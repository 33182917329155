import React from "react";
import { Button } from "react-onsenui";
import styles from "./QrLogin.module.scss";

const QrLogin = ({ handleQrLogin }) => (
  <div className={styles["k42-qr-content"]}>
    <Button
      className={styles["k42-qr-btn"]}
      modifier="material--flat"
      ripple={false}
      onClick={handleQrLogin}>
      <img
        className={styles["k42-qr-image"]}
        src={
          process.env.REACT_APP_VENDOR === "knm"
            ? `${process.env.PUBLIC_URL}/img/qr-code-knm.png`
            : `${process.env.PUBLIC_URL}/img/qr-code.png`
        }
        alt=""
      />
    </Button>
  </div>
);

export default QrLogin;
