import React from "react";
import { withTranslation } from "react-i18next";
import styles from "./JobList.module.scss";
import "./JobListRow.scss";
import Icon from "../Icon";
import { ListItem, Checkbox } from "react-onsenui";
import {
  JOB_DUPLEX,
  JOB_COLOR,
  JOB_FAVOURITE,
  PRINT,
  DELETE,
  FAVORITE,
  TOGGLE
} from "../../constants/General";
import getFormattedPrice from "../../common/utils/getFormattedPrice";
import getCountryInfoByLanguage from "../../common/utils/getCountryInfoByLanguage";
import { getCurrentLanguage } from "../../actions/auth";

class JobListRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.scroll = 1;
  }

  autoScroll = (scroll, row) => {
    let carouselElement = document.getElementsByTagName("ons-carousel")[this.props.index];
    if (!carouselElement) return;
    // INSTANT PRINT
    if (scroll < 0.6) {
      if (!row.selected) this.state.toggleRow(row);
      this.props.action(row.printAction);
      carouselElement.setActiveIndex(1);
    }
    // PRINT
    if (scroll > 0.6 && scroll < 0.9) {
      carouselElement.setActiveIndex(0.85);
    }
    // FAVOURITE
    if (scroll > 1.1 && scroll < 1.4) {
      carouselElement.setActiveIndex(1.15);
    }
    // INSTANT DELETE
    if (scroll > 1.4) {
      // select row only when not already selected
      if (!row.selected) this.state.toggleRow(row);
      this.props.action(DELETE);
      carouselElement.setActiveIndex(1);
    }
    // RETURN
    if (scroll > 0.9 && scroll < 1.1) {
      carouselElement.setActiveIndex(1);
    }
  };

  handleClick = (row, action) => {
    // CLICK ACTIONS
    if (action === TOGGLE) {
      return this.state.toggleRow(row);
    }
    if (action === PRINT) {
      if (!row.selected) this.state.toggleRow(row);
      this.props.action(row.printAction);
    }
    if (action === FAVORITE) {
      if (!row.selected) this.state.toggleRow(row);
      this.props.action(row.favoriteAction);
    }
    if (action === DELETE) {
      if (!row.selected) this.state.toggleRow(row);
      this.props.action(action);
    }
    let carouselElement = document.getElementsByTagName("ons-carousel")[this.props.index];
    if (carouselElement) carouselElement.setActiveIndex(1);
  };

  setScroll = scrollIndex => {
    this.scroll = scrollIndex;
  };

  renderFavourite = row => {
    return (
      <label className={`left swipe-favorite ${styles["k42-swipe-favorite"]}`}>
        <Icon name={row.favoriteAction} />
      </label>
    );
  };

  renderDelete = () => {
    return (
      <label className={`center swipe-delete ${styles["k42-swipe-delete"]}`}>
        <Icon name={DELETE} />
      </label>
    );
  };

  renderRowContent = () => {
    const rowPrice = getFormattedPrice(
      (getCountryInfoByLanguage(getCurrentLanguage()) || { countryCode: "US", currency: "USD" })
        .currency,
      this.props.row.price,
      this.props.country
    );
    return (
      <ListItem className="job-list-row" modifier="nodivider">
        <label className="left">
          <Checkbox
            checked={this.props.row.selected}
            inputId={`checkbox-${this.props.index}`}
            onClick={() => this.handleClick(this.props.row, TOGGLE)}
          />
        </label>
        <label
          className={`center ${styles["k42-row-column"]}`}
          onClick={() => this.handleClick(this.props.row, TOGGLE)}>
          <div
            className={`${styles["k42-item-label"]} ${this.props.row.selected &&
              styles["k42-item-label-checked"]}`}>
            {this.props.t(this.props.row.name)}
          </div>
          <div className={styles["k42-item-value"]}>
            {rowPrice} {this.props.row.pagesWithFormat} {this.props.row.date}
          </div>
        </label>
        <label className={`right`}>
          <span className={styles["k42-job-icon"]}>
            {this.props.row.favorite && <Icon name={JOB_FAVOURITE} />}
          </span>
          <span className={styles["k42-job-icon"]}>
            {this.props.row.color && <Icon name={JOB_COLOR} />}
          </span>
          <span className={styles["k42-job-icon"]}>
            {this.props.row.duplex && <Icon name={JOB_DUPLEX} />}
          </span>
        </label>
      </ListItem>
    );
  };

  renderPrint = () => {
    return (
      <ListItem className={`${styles["k42-swipe-print"]}`}>
        <label className="right">
          <Icon name={PRINT} />
        </label>
      </ListItem>
    );
  };

  render() {
    // const row = this.props.row;

    return (
      // <Carousel
      //   key={this.props.index}
      //   index={1}
      //   onMouseUp={() => this.autoScroll(this.scroll, row)}
      //   onTouchCancel={() => this.autoScroll(this.scroll, row)}
      //   onTouchEnd={() => this.autoScroll(this.scroll, row)}
      //   overscrollable>
      //   <CarouselItem
      //     onClick={() => this.handleClick(row, PRINT)}
      //     clasName={styles["k42-carousel-list-item"]}>
      //     {this.renderPrint()}
      //   </CarouselItem>
      //   <CarouselItem onClick={() => this.handleClick(row, TOGGLE)}>
      //     {this.renderRowContent()}
      //   </CarouselItem>
      //   <CarouselItem onClick={() => this.handleClick(row, FAVORITE)}>
      //     <ListItem className={styles["k42-carousel-list-item"]}>
      //       {this.renderFavourite(row)}
      //       {this.renderDelete()}
      //     </ListItem>
      //   </CarouselItem>
      // </Carousel>
      <React.Fragment>{this.renderRowContent()}</React.Fragment>
    );
  }
}

export default withTranslation()(JobListRow);
