import React from "react";
import { Button, Col } from "react-onsenui";
import Icon from "../Icon";
import MediaQuery from "react-responsive";

let styles = {};
import(/* webpackMode: "eager" */
`./${process.env.REACT_APP_VENDOR}/DialPad.module.scss`).then(importedStyles => {
  styles = importedStyles.default;
});

const DialButton = ({ value, callback }) => {
  const handleClick = event => {
    event.preventDefault();
    callback(value);
  };

  return (
    <Col>
      <Button
        className={styles["k42-dial-button"]}
        modifier="material--flat"
        ripple={false}
        style={{
          paddingTop: typeof value === "string" && 20
        }}
        onClick={handleClick}>
        {typeof value === "number" && value}

        {typeof value === "string" && (
          <>
            <MediaQuery maxDeviceWidth={900}>
              <Icon name={value} size={"small"} />
            </MediaQuery>
            <MediaQuery minDeviceWidth={901}>
              <Icon name={value} size={"medium"} />
            </MediaQuery>
          </>
        )}
      </Button>
    </Col>
  );
};

export default DialButton;
