import React from "react";
import { Input, Page } from "react-onsenui";
import styles from "./Dialog.module.scss";
import Icon from "../Icon";

const DialogPrompt = ({ label, handleAction, password }) => {
  return (
    <Page>
      <section className={styles["k42-dialog-prompt-box"]}>
        {label && label[0].type === "numberinput" && (
          <label className={styles["k42-dialog-prompt-label"]}>{label[0].name}</label>
        )}

        <Input
          float
          className={styles["k42-dialog-prompt-input"]}
          value=""
          type={password ? "password" : "text"}
          onChange={event => {
            handleAction(event.target.value);
          }}
          modifier="material"
          placeholder=""
        />
        {label && label[0].type === "numberinput" && (
          <Icon className={styles["k42-dialog-icon"]} name={"prompt"} size="small" />
        )}
      </section>
    </Page>
  );
};

export default DialogPrompt;
