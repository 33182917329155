import React from "react";
import { Row } from "react-onsenui";
import DialButton from "./DialButton";
import { DEL, ENTER } from "./../../constants/General";

let styles = {};
import(
  /* webpackMode: "eager" */
  `./${process.env.REACT_APP_VENDOR}/DialPad.module.scss`
).then(importedStyles => {
  styles = importedStyles.default;
});

const DialPad = ({ callback }) => {
  const keys = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    [DEL, 0, ENTER]
  ];

  return (
    <div>
      {keys.map((keygroup, index) => (
        <Row key={index} className={styles["k42-login-row"]}>
          {keygroup.map(key => (
            <DialButton key={key} value={key} callback={callback} />
          ))}
        </Row>
      ))}
    </div>
  );
};

export default DialPad;
