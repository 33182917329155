import React from "react";
import { withTranslation } from "react-i18next";
import Icon from "../Icon";
import { SELECT_ALL, UNSELECT_ALL } from "../../constants/General";

let styles = {};
import(/* webpackMode: "eager" */
`./${process.env.REACT_APP_VENDOR}/TopMenu.module.scss`).then(importedStyles => {
  styles = importedStyles.default;
});

const TopMenu = ({
  tabs,
  action,
  toggleAll,
  navigateBack,
  isActionMenu,
  pricesSum,
  allJobsSelected,
  oneJobSelected,
  userCredit,
  hidden,
  t
}) => {
  const renderTab = (tab, index) => {
    const settingsTabDisabled = tab.action === "SETTINGS" && !oneJobSelected;
    return (
      tab.visible && ( // eslint-disable-next-line
        <a
          key={index}
          onClick={() => !settingsTabDisabled && action(tab.action)}
          className={tab.active ? styles.button_selected : styles.button}>
          <Icon
            name={tab.active ? tab.action + "_invert" : tab.action}
            size="medium"
            className={`${styles["button-icon"]} ${
              settingsTabDisabled ? styles["button-icon-disabled"] : ""
            }`}
          />
        </a>
      )
    );
  };
  const getActiveTabName = tabs => {
    let activeTab = tabs.filter(tab => tab.active);
    if (activeTab.length > 0) {
      return t(activeTab[0].name);
    }
    return t("Selected");
  };

  const getCreditOrSumRow = () => {
    if (isActionMenu) {
      return `${t("Price")}: ${pricesSum}`;
    } else {
      return ""; // `${t("Credit")}: ${userCredit}`;
    }
  };

  const renderTopMenu = () => {
    return (
      <div className={isActionMenu ? styles["topmenu-action"] : styles.topmenu}>
        <div onClick={navigateBack} className={styles.container}>
          <Icon className={styles.icon_left} name={"back"} size="large" />
        </div>
        <div className={styles["topmenu-header"]}>
          <div className={styles["topmenu-header-row"]}>{getActiveTabName(tabs)}</div>
          <div className={styles["topmenu-header-row"]}>{getCreditOrSumRow()}</div>
        </div>
        <div onClick={toggleAll} className={styles["button-select-all"]}>
          {allJobsSelected ? (
            <Icon name={UNSELECT_ALL} className={styles["icon-select-all"]} size="large" />
          ) : (
            <Icon name={SELECT_ALL} className={styles["icon-select-all"]} size="large" />
          )}
        </div>
        <div className="k42-spacer" />
        {tabs.map(renderTab)}
      </div>
    );
  };

  return !hidden && renderTopMenu();
};

export default withTranslation()(TopMenu);
