export const DEL = "icon__198";
export const ENTER = "icon__196";
export const LOGOUT = "icon__62";
export const BACK = "icon__47";
export const BACK_BLACK = "icon__49";
export const USER = "icon__61";
export const KEYBOARD = "icon__99";
export const DIALPAD = "icon__130";
export const QR = "icon__132";

export const A1 = "icon__42";
export const A2 = "icon__78";
export const A3 = "icon__56";
export const A4 = "icon__09";
export const A106 = "icon__09";
export const A5 = "icon__180";
export const A7 = "icon__183";
export const A8 = "icon__12";
export const A9 = "icon__12";
export const A91 = "icon__12";
export const A10 = "icon__21";
export const A20 = "icon__39";
export const A201 = "icon__36";
export const A202 = "icon__223";
export const A203 = "icon__12";
export const A204 = "icon__180";
export const A205 = "icon__36";
export const A206 = "icon__180";
export const A400 = "icon__21";
export const A410 = "icon__21";
export const A402 = "icon__150";
export const A500 = "icon__44";

export const LOCK = "icon__107";
export const PROMPT = "icon__101";
export const PASSWORD = "icon__101";
export const CHECKBOX = "icon__104";
export const DRILLDOWN = "icon__105";
export const CHECKED = "icon__104";
export const UNCHECKED = "icon__103";

export const READY = "icon__70";
export const READY_INVERT = "icon__71";
export const FAVORITE = "icon__72";
export const FAVORITE_INVERT = "icon__73";
export const PRINTED = "icon__74";
export const PRINTED_INVERT = "icon__75";

export const PRINT = "icon__06";
export const PRINTALL = "icon__78";
export const SETTINGS = "icon__199";
export const DUPLICATE = "icon__78";
export const MARK_FAVORITE = "icon__79";
export const UNMARK_FAVORITE = "icon__80";
export const DELETE = "icon__81";

export const JOB_FAVOURITE = "icon__145";
export const JOB_COLOR = "icon__146";
export const JOB_DUPLEX = "icon__147";
export const SELECT_ALL = "icon__143";
export const UNSELECT_ALL = "icon__140";
export const ROTATE = "icon__rotate";

export const INFO = "icon__153";
export const CLOSE = "icon__161";

export const PLUS = "icon-plus";
export const MINUS = "icon-minus";
