import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Icon from "../../components/Icon";
import { Page, List, ListItem, Checkbox, Button, Input } from "react-onsenui";
import styles from "./Dialog.module.scss";

const DialogPage = ({ fields, dialogActions, handleAction, name, t }) => {
  const [count, setCopyCount] = useState(1);

  const handleClick = (action, value, name) => {
    handleAction(action, value, name);
  };
  const renderRow = (row, index) => {
    return (
      <ListItem
        className={row.type !== "boldText" ? styles["k42-dialog-list-item"] : styles["k42-dialog-list-bold-item"]}
        key={index}
        onClick={() => handleClick(row.action, row.name, name)}
        tappable>
        {renderLeftLabel(row, index)}
        {renderCenterLabel(row, index)}
        {renderRightLabel(row, index)}
      </ListItem>
    );
  };

  const renderLeftLabel = (row, index) => {
    return (
      <label className={`left ${styles["k42-dialog-item"]}`}>
        {row.required && <div className={styles["k42-required"]} />}
      </label>
    );
  };

  const renderCenterLabel = (row, index) => {
    return (
      <label htmlFor={`dialog-checkbox-${index}`} className={`center ${styles["k42-dialog-item"]}`}>
        <div className={styles["k42-dialog-item-label"]}>{t(row.name)}</div>
        {row.type !== "password" && (
          <div className={styles["k42-dialog-item-value"]}>{t(row.value)}</div>
        )}
        {row.type === "password" && (
          <input
            readOnly
            className={styles["k42-dialog-item-value"]}
            type="password"
            value={row.value}
          />
        )}
      </label>
    );
  };

  const renderRightLabel = (row, index) => {
    switch (row.type) {
      case "checkbox":
        return renderCheckboxAttachment(row, index);
      case "incrementor":
        return renderIncrementorAttachment(row, index);
      default:
        return renderIconAttachment(row, index);
    }
  };

  const renderIconAttachment = (row, index) => {
    return (
      <label className="right">
        <Icon name={row.type} />
      </label>
    );
  };

  const renderCheckboxAttachment = (row, index) => {
    return (
      <label className="right">
        <Checkbox
          checked={row.checked || false}
          className={styles["k42-dialog-item-checkbox"]}
          inputId={`dialog-checkbox-${index}`}
        />
      </label>
    );
  };

  const renderIncrementorAttachment = (row, index) => {
    return (
      <label className="right">
        <Button
          className={styles["k42-dialog-item-incrementor"]}
          onClick={() => {
            setCopyCount(count - 1);
            dialogActions.saveValue("Copies", count - 1);
          }}
          disabled={count === 1}>
          <Icon name={"minus"} size={"mini"} />
        </Button>
        <Input
          className={styles["k42-dialog-item-text-input"]}
          value={count.toString()}
          type={"text"}
          modifier="material"
          placeholder=""
        />
        <Button
          className={styles["k42-dialog-item-incrementor"]}
          onClick={() => {
            setCopyCount(count + 1);
            dialogActions.saveValue("Copies", count + 1);
          }}>
          <Icon name={"plus"} size="mini" />
        </Button>
      </label>
    );
  };

  return (
    <Page className={styles["k42-dialog-list"]}>
      <List dataSource={fields} renderRow={renderRow} />
    </Page>
  );
};

export default withTranslation()(DialogPage);
