import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import styles from "./Animation.module.scss";
import Icon from "../Icon";
import { useSelector } from "react-redux";
import OneStepGuide from "../../containers/Guide/OneStepGuide";
import { useHistory } from "react-router-dom";

const Animation = ({ match, t }) => {
  const pathRegEx = /\/animation/g;
  let pageLayout = "";
  const dialog = useSelector(state => state.dialog);
  const [documentType, setDocumentType] = useState("");
  const history = useHistory();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setDocumentType(match.params.documentType);
  }, [dialog]);

  const folders = new Map([
    ["estimate", "estimates"],
    ["purchase-agreement", "purchase_agreements"],
    ["invoice", "invoices"],
    ["contract", "purchase_agreements"],
    ["purchase-order", "purchase_orders"]
  ]);

  const closeAnimation = (defaultTime = 2000) => {
    setTimeout(() => {
      if (pathRegEx.test(history.location.pathname)) history.push("/dashboard");
    }, defaultTime * match.params.count);
  };

  switch (match.params.type) {
    case "print":
      closeAnimation();
      pageLayout = (
        <div className={styles["k42-animation"]}>
          <div className={styles["k42-animation-container"]}>
            <img
              className={styles["k42-page-print"]}
              alt={t(`Printed Page`)}
              src={`${process.env.PUBLIC_URL}/img/paper.png`}
            />
          </div>
        </div>
      );
      break;
    case "scan":
      closeAnimation();
      pageLayout = (
        <div className={styles["k42-animation"]}>
          <div className={styles["k42-animation-container"]}>
            <div className={styles["k42-page-scan"]} />
            <img
              className={styles["k42-page"]}
              alt={t(`Printed Page`)}
              src={`${process.env.PUBLIC_URL}/img/paper.png`}
            />
          </div>
        </div>
      );
      break;
    case "copy":
      closeAnimation();
      pageLayout = (
        <div className={styles["k42-animation"]}>
          <div className={styles["k42-animation-container"]}>
            <div className={styles["k42-page-scan"]} />
            <img
              className={styles["k42-page-print"]}
              alt={t(`Printed Page`)}
              src={`${process.env.PUBLIC_URL}/img/paper.png`}
            />
          </div>
        </div>
      );
      break;
    case "folder-browse":
      pageLayout = (
        <>
          <button className={styles["k42-close-button"]} onClick={() => closeAnimation(0)}>
            <Icon name={"close"} />
          </button>
          <div className={styles["k42-animation-container"]}>
            <img
              className={styles["k42-page-pdf-pass"]}
              alt={t(`Folder browse`)}
              src={`${process.env.PUBLIC_URL}/img/folder-browse.png`}
            />
          </div>
        </>
      );
      break;
    case "pdf-pass":
      pageLayout = (
        <>
          <button className={styles["k42-close-button"]} onClick={() => closeAnimation(0)}>
            <Icon name={"close"} />
          </button>
          <div className={styles["k42-animation-container"]}>
            <img
              className={styles["k42-page-pdf-pass"]}
              alt={t(`PDF Password`)}
              src={`${process.env.PUBLIC_URL}/img/pdf-pass.png`}
            />
          </div>
        </>
      );
      break;
    case "scan-navigate":
      pageLayout = (
        <div className={styles["k42-animation"]}>
          <div className={styles["k42-animation-container"]}>
            <div className={styles["k42-page-scan"]} />
            <img
              className={styles["k42-page"]}
              alt={t(`Printed Page`)}
              src={`${process.env.PUBLIC_URL}/img/paper.png`}
            />
          </div>
        </div>
      );
      break;
    case "pdf-protection":
      closeAnimation();
      pageLayout = (
        <div className={styles["k42-animation"]}>
          <div className={styles["k42-animation-container"]}>
            <div className={styles["k42-classification-scan"]} />
            <div className={styles["k42-pdf-protection-scan"]}>
              <div
                style={{ width: "50%", height: "75%", display: "flex", justifyContent: "center" }}>
                <img
                  className={styles["k42-page"]}
                  alt={t(`Printed Page`)}
                  src={`${process.env.PUBLIC_URL}/img/invoice.png`}
                />
              </div>
              <div className={styles["k42-watermark"]}>
                {localStorage.getItem("pdf-protection-secret") !== "Unclassified"
                  ? localStorage.getItem("pdf-protection-secret").toUpperCase()
                  : ""}
              </div>
            </div>
            <span style={{ marginTop: 20 }}>For illustration purposes only</span>
          </div>
        </div>
      );
      break;
    case "classification-scan":
      pageLayout = (
        <div className={styles["k42-animation"]}>
          <div className={styles["k42-animation-container"]}>
            <div className={styles["k42-classification-scan"]} />
            <div style={{ width: "50%", height: "75%", display: "flex", justifyContent: "center" }}>
              <img
                className={styles["k42-page"]}
                alt={t(`Printed Page`)}
                src={`${process.env.PUBLIC_URL}/img/${documentType}.png`}
              />
            </div>
            <span style={{ marginTop: 20 }}>For illustration purposes only</span>
          </div>
        </div>
      );

      if (history.action === "POP") {
        history.push("/dashboard");
      }
      break;
    case "classification":
      pageLayout = (
        <>
          <button
            className={styles["k42-close-button"]}
            onClick={() => history.push("/dashboard")}
            style={{ zIndex: 101 }}>
            <Icon name={"close"} />
          </button>
          <div className={styles["k42-animation-container"]}>
            <div className={styles["k42-image-container"]}>
              <div style={{ position: "relative" }}>
                <img
                  alt={t(`${documentType}`)}
                  src={`${process.env.PUBLIC_URL}/img/SharePoint_${folders.get(documentType)}.png`}
                  onLoad={() => setImageLoaded(true)}
                />
                {!imageLoaded ? (
                  <div style={{ textAlign: "center" }}>Loading...</div>
                ) : (
                  <span
                    id="classification-container"
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      fontFamily: "Segoe",
                      color: "black",
                      padding: "5px",
                      top: documentType === "invoice" && "47%"
                    }}>
                    {localStorage.getItem("fileName")}.pdf
                  </span>
                )}
              </div>
            </div>
          </div>
          {imageLoaded && <OneStepGuide id="guide" stepName="classification" />}
        </>
      );
      break;
    case "onboarding":
      closeAnimation();
      pageLayout = (
        <>
          <button
            className={styles["k42-close-button"]}
            onClick={() => history.push("/dashboard")}
            style={{ zIndex: 101 }}>
            <Icon name={"close"} />
          </button>
          <div className={styles["k42-animation"]}>
            <div className={styles["k42-animation-container"]}>
              <div className={styles["k42-classification-scan"]} />
              <div
                style={{ width: "50%", height: "75%", display: "flex", justifyContent: "center" }}>
                {documentType !== "contacts" ? (
                  <img
                    className={styles["k42-page"]}
                    alt={t(`${documentType}`)}
                    src={`${process.env.PUBLIC_URL}/img/${documentType}.jpeg`}
                  />
                ) : (
                  <img
                    className={styles["k42-page"]}
                    alt={t(`${documentType}`)}
                    src={`${process.env.PUBLIC_URL}/img/contact_${localStorage.getItem(
                      "contact-name"
                    )}.jpeg`}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      );
      break;
    default:
      pageLayout = "animation";
  }

  return <div className={styles["k42-animation"]}>{pageLayout}</div>;
};

export default withTranslation()(Animation);
