import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import tracker from "../middleware/tracker";
import rootReducer from "../reducers";

const middleware = [thunk, tracker];

const storeFactory = (initialState = {}) =>
  applyMiddleware(...middleware)(createStore)(
    rootReducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

export default storeFactory;
