import React from "react";
import { menuActions, authActions } from "../../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Navbar from "../../components/Navbar";
import styles from "./IDCardRegistration.module.scss";
import { withTranslation } from "react-i18next";
import getLogoUrlByWidth from "../../common/utils/getLogoUrlByWidth";

class IDCardRegistration extends React.Component {
  render() {
    const { auth: fullname, authActions, t } = this.props;

    return (
      <div className={styles["k42-application-list"]}>
        <Navbar
          level={1}
          user={fullname}
          colorOverride={true}
          navigateBack={this.props.history.goBack}
          logout={authActions.logoutUser}
        />

        <img src={getLogoUrlByWidth(600)} alt="" height="10%" />

        <p>{t("ID Card registration")}</p>
        <p>{t("Place your card to the reader")}</p>
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  menuActions: bindActionCreators(menuActions, dispatch),
  authActions: bindActionCreators(authActions, dispatch)
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(IDCardRegistration)
);
