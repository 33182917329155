import React from "react";
import LoginPanel from "../../components/LoginPanel/LoginPanel";
import { authActions, menuActions } from "../../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button } from "react-onsenui";
import styles from "./Login.module.scss";
import Icon from "../../components/Icon/Icon";
import { QR, DIALPAD } from "../../constants/General";
import { withTranslation } from "react-i18next";

class Login extends React.Component {
  state = {
    dialPadOpen: true
  };

  handleLogin = async (username, password) => {
    await this.props.authActions.loginUser(username, password, this.state.locationInfo);
    if (this.props.authenticated) {
      this.props.history.push(`/dashboard`);
    } else {
      this.props.history.push(`/login-failed/${this.props.message}/${this.props.cause}`);
      setTimeout(() => this.props.history.goBack(), 3000);
    }
  };

  showQrButton = () => {
    return (
      process.env.REACT_APP_VENDOR !== "ta" &&
      process.env.REACT_APP_VENDOR !== "utax" &&
      process.env.REACT_APP_VENDOR !== "knm"
    );
  };

  render() {
    return (
      <div className={styles["k42-login-container"]}>
        <div className={styles["k42-login-dial-pad-switch"]}>
          <Button
            modifier="material--flat"
            ripple={false}
            onClick={() =>
              this.setState({
                dialPadOpen: true
              })
            }
            disabled={this.state.dialPadOpen}>
            <Icon name={DIALPAD} size="large" />
          </Button>
          {this.showQrButton() && (
            <Button
              modifier="material--flat"
              ripple={false}
              onClick={() =>
                this.setState({
                  dialPadOpen: false
                })
              }
              disabled={!this.state.dialPadOpen}>
              <Icon name={QR} size="large" />
            </Button>
          )}
        </div>
        <LoginPanel login={this.handleLogin} dialpad={this.state.dialPadOpen} />
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state.auth, user: state.auth });

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(authActions, dispatch),
  menuActions: bindActionCreators(menuActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Login));
