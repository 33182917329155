import { ActionTypes } from "../constants";
import languageValueToName from "../common/utils/languageValueToName";
import { getCurrentLanguage, setLocalStorage } from "./auth";

const getActions = async () => {
  const res = await fetch(
    `${process.env.PUBLIC_URL}/data/actions.${process.env.REACT_APP_VENDOR}.json`
  );
  const json = await res.json();
  // set default value for language selection
  json.A20.content[3].value = languageValueToName(getCurrentLanguage());
  return json;
};

export const openDialog = (id, label) => {
  return async dispatch => {
    const actions = await getActions();
    const menu = actions[id];
    if (menu) {
      dispatch(openDialogSuccess(menu, label));
    } else {
      dispatch(openDialogFailure());
    }
  };
};

const openDialogSuccess = (dialog, label) => ({
  type: ActionTypes.OPEN_DIALOG_SUCCESS,
  label: label,
  message: "Dialog menu opened.",
  dialog: dialog
});

const openDialogFailure = () => ({
  type: ActionTypes.OPEN_DIALOG_FAILURE,
  message: "Open dialog menu failed."
});

export const closeDialog = () => ({
  type: ActionTypes.CLOSE_DIALOG_SUCCESS,
  message: "Dialog menu closed."
});

export const setActiveTab = index => ({
  type: ActionTypes.SET_DIALOG_ACTIVE_TAB,
  message: "New active tab set.",
  activeTab: index
});

export const saveValue = (name, value) => {
  return dispatch => {
    if (name === "Language Selection") {
      // setLocalStorage(store.getState().auth.username, languageNameToValue(value));
      // dispatch(changeLanguage(languageNameToValue(value)));
    } else if (name === "Document Classification") {
      // Have to use localStorage here, because the value of the input field
      // doesn't get stored for some reason and I couldn't figure out why.
      setLocalStorage("fileName", value);
    }
    dispatch({
      type: ActionTypes.SAVE_DIALOG_VALUE,
      message: "Value saved",
      value: value,
      name: name
    });
  };
};
