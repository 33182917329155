import { ActionTypes } from "../constants/";

export const getMenu = type => {
  return async dispatch => {

    const res = await fetch(
      `${
        process.env.PUBLIC_URL
      }/data/top_menu_${type.toLowerCase()}.json?time=${new Date().getTime()}`
    );

    const json = await res.json();
    const menu = await json.children;
    if (menu) {
      dispatch(getMenuSuccess(menu));
    } else {
      dispatch(getMenuFailure());
    }
  };
};

const getMenuSuccess = menu => ({
  type: ActionTypes.GET_MENU_SUCCESS,
  message: "Gathering menu successful",
  menu: menu
});

export const resetMenu = () => ({
  type: ActionTypes.RESET_MENU
});

const getMenuFailure = () => ({
  type: ActionTypes.GET_MENU_FAILURE,
  message: "Gathering menu failed",
  cause: "Unknown type of top menu"
});

export const printAll = id => ({
  type: ActionTypes.PRINT_ALL,
  message: "All jobs finished",
  id: id
});

export const displaySubMenu = menu => getMenuSuccess(menu);

export const navigateBack = () => ({
  type: ActionTypes.REMOVE_MENU_SUCCESS
});
