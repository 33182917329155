import React from "react";
import { withTranslation } from "react-i18next";
import Icon from "../Icon";

let styles = {};

const vendor = process.env.REACT_APP_VENDOR;

import(/* webpackMode: "eager" */
`./${vendor}/Tile.module.scss`).then(importedStyles => {
  styles = importedStyles.default;
});

const Tile = ({ data, click, t, jobs, user, customStyle }) => {
  const isGreyTile =
    (jobs && jobs.count <= 0) ||
    (jobs && user.credit && user.credit <= 0) ||
    (jobs && user.quota && user.quota <= 0);

  const isCustomStyle = customStyle ? `A${customStyle}_${data.action}` : `A${data.action}`;

  return (
    <div
      className={
        isCustomStyle +
        ` ${styles["k42-tile"]} ${isGreyTile ? (customStyle ? `${customStyle}_0` : "A0") : ""}`
      }
      onClick={() =>
        jobs && jobs.count > user.copiesLeft && user.copiesLeft
          ? alert("Not enough copies left")
          : click(data)
      }>
      <div className="k42-spacer" />
      <div className={styles["k42-tile-icons"]}>
        <Icon
          className={styles["k42-tile-logo"]}
          name={"A" + data.action}
          size="large"
          user={user.id}
        />
        {jobs && <h2 className={styles["k42-tile-attachment"]}>{jobs.count}</h2>}
      </div>
      <div className="k42-spacer" />
      <span className={styles["k42-tile-title"]}>
        {t(data.name)} <br />
        {jobs && jobs.sum}
      </span>
    </div>
  );
};

export default withTranslation()(Tile);
