import ReactGA from "react-ga";

const trackPardot = () => {
  window.piAId = "140201";
  window.piCId = "33512";
  window.piHostname = "pi.pardot.com";
  (function() {
    function async_load() {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src =
        ("https:" === document.location.protocol ? "https://pi" : "http://cdn") +
        ".pardot.com/pd.js";
      var c = document.getElementsByTagName("script")[0];
      c.parentNode.insertBefore(s, c);
    }
    if (window.attachEvent) {
      window.attachEvent("onload", async_load);
    } else {
      window.addEventListener("load", async_load, false);
    }
  })();
};
export default () => {
  switch (process.env.REACT_APP_STAGE) {
    case "prod":
      trackPardot();
      ReactGA.initialize(process.env.REACT_APP_GA_CODE_PROD);
      break;
    case "dev":
      ReactGA.initialize(process.env.REACT_APP_GA_CODE_DEV);
      break;
    default:
      break;
  }
};
