import {
  GET_MENU_FAILURE,
  GET_MENU_REQUEST,
  GET_MENU_SUCCESS,
  LOGOUT_SUCCESS,
  PRINT_ALL,
  REMOVE_MENU_SUCCESS,
  PRINT_JOBS_SUCCESS,
  DELETE_JOBS_SUCCESS,
  RESET_MENU
} from "../constants/ActionTypes";

const initialState = [];

export default function menu(state = initialState, action) {
  switch (action.type) {
    case GET_MENU_REQUEST:
      return state;

    case GET_MENU_SUCCESS:
      return [...state, action.menu];

    case REMOVE_MENU_SUCCESS:
      return [...state.slice(0, Math.max(state.length - 1, 1))];

    case GET_MENU_FAILURE:
      return initialState;
    case RESET_MENU:
      return initialState;
    case LOGOUT_SUCCESS:
      return initialState;

    case PRINT_ALL:
      return state.map(menu =>
        menu.map(tile => {
          if (tile.id !== action.id) {
            return tile;
          }
          return { ...tile, count: "0" };
        })
      );

    case PRINT_JOBS_SUCCESS:
      return state.map(menu =>
        menu.map(tile => {
          if (tile.id !== "M0.0") {
            return tile;
          }
          return { ...tile, count: Math.max(parseInt(tile.count) - action.jobsCount, "0") };
        })
      );

    case DELETE_JOBS_SUCCESS:
      return state.map(menu =>
        menu.map(tile => {
          if (tile.id !== "M0.0") {
            return tile;
          }
          return { ...tile, count: Math.max(parseInt(tile.count) - action.jobsCount, "0") };
        })
      );

    default:
      return state;
  }
}
