import React, { Component } from "react";
import FlagImage from "../../components/Flag/FlagImage";
import getCountryInfoByLanguage from "../../common/utils/getCountryInfoByLanguage";
import LanguageValueToName, { langKeys } from "../../common/utils/languageValueToName";
import styles from "./Flag.module.scss";
import { getCurrentLanguage } from "../../actions/auth";

class Flag extends Component {
  state = {
    open: false
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ open: false });
    }
  };

  renderFlag = object => {
    return (
      <li
        onClick={() => {
          this.setState({ open: !this.state.open });
          this.props.authActions.changeAppLanguage(object.langCode);
          this.props.authActions.changeLanguage(object.langCode);
          this.props.refresh()
        }}>
        <FlagImage name={object.countryCode} />
        <span>{object.language}</span>
      </li>
    );
  };
  render() {
    let languages = [];
    langKeys.map(language =>
      languages.push({
        langCode: language,
        get countryCode() {
          return (getCountryInfoByLanguage(this.langCode) || { countryCode: "US", currency: "USD" })
            .countryCode;
        },
        get language() {
          return LanguageValueToName(this.langCode);
        }
      })
    );

    if (process.env.REACT_APP_VENDOR === "knm") {
      languages = languages.filter(
        language => language.langCode !== "zn-tw" && language.langCode !== "zn-cn"
      );
    }

    return (
      <div className={styles["flagContainer"]} ref={this.setWrapperRef}>
        <div
          className={styles["flag"]}
          onClick={() => {
            this.setState({ open: !this.state.open });
          }}>
          <FlagImage
            name={
              (
                getCountryInfoByLanguage(getCurrentLanguage()) || {
                  countryCode: "US",
                  currency: "USD"
                }
              ).countryCode
            }
            big={true}
          />
        </div>
        {this.state.open && (
          <div className={styles["listWrapper"]}>
            <ul className={styles["list"]}>
              {languages.map((object, i) => {
                return languages[i + 1] ? (
                  <div key={i}>
                    {this.renderFlag(object)}
                    <hr />
                  </div>
                ) : (
                    <div key={i}>{this.renderFlag(object)}</div>
                  );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default Flag;
